import api from '~/services/api';
import {
  CapaData,
  ParamentrosValidacao,
  DadosErpDoFornecedor,
  ProductDataToTable,
} from '../../protocols';
import { validaItensDoXML } from '../Validacao';
import { toast } from 'react-toastify';

interface RevalidaProps {
  dadosItemACorrigir: ProductDataToTable;
  parametrosValidacao: ParamentrosValidacao;
  dadosDaCapa: CapaData;
  dadosErpDoFornecedor: DadosErpDoFornecedor;
}

export default async function retornarAoMix(
  props: RevalidaProps,
): Promise<ProductDataToTable> {
  const {
    dadosItemACorrigir,
    parametrosValidacao,
    dadosDaCapa,
    dadosErpDoFornecedor,
  } = props;
  const formatedItem = dadosItemACorrigir;
  try {
    const { data } = await api.put('/retornaAoMix', {
      cod_loja: dadosItemACorrigir.cod_loja,
      cod_produto: dadosItemACorrigir.cod_produto,
    });
    if (data && data.success) {
      formatedItem.flg_inativo = false;
      // remove status fora do mix
      const cleanStatus = dadosItemACorrigir.statusDaValidacao.filter(
        (status) => {
          return status.codStatus !== 2;
        },
      );

      dadosItemACorrigir.statusDaValidacao = cleanStatus;

      const itensValidados = await validaItensDoXML(
        formatedItem,
        dadosDaCapa,
        parametrosValidacao,
        dadosErpDoFornecedor,
      );

      const qtdStatusBloq = itensValidados?.statusDaValidacao.filter(
        (status) => status.restricao === 'BLOQ',
      );
      const qtdStatusNe = itensValidados?.statusDaValidacao.filter(
        (status) => status.restricao === 'NE',
      );
      const qtdStatusMix = itensValidados?.statusDaValidacao.filter(
        (status) => status.restricao === 'MIX ',
      );
      const qtdStatusInf = itensValidados?.statusDaValidacao.filter(
        (status) => status.restricao === 'INF',
      );
      itensValidados.qtdStatusBloq = Number(qtdStatusBloq?.length);
      itensValidados.qtdStatusNe = Number(qtdStatusNe?.length);
      itensValidados.qtdStatusMix = Number(qtdStatusMix?.length);
      itensValidados.qtdStatusInf = Number(qtdStatusInf?.length);
      toast.success('Produto retornado ao Mix da Loja');
      return itensValidados;
    }
    return dadosItemACorrigir;
  } catch (error: any) {
    if (error.data && error.data.message) {
      toast.error(error.data.message);
      return dadosItemACorrigir;
    }
    toast.error(String(error));
    return dadosItemACorrigir;
  }
}
