import api from '~/services/api';
import { formataItensParaTabela, validaItensDoXML } from '../Validacao';
import {
  CapaData,
  ParamentrosValidacao,
  DadosErpDoFornecedor,
  ProductDataToTable,
} from '../../protocols';

interface RevalidaProps {
  dadosItemACorrigir: ProductDataToTable;
  listaDeStatus: number[];
  parametrosValidacao: ParamentrosValidacao;
  dadosDaCapa: CapaData;
  dadosErpDoFornecedor: DadosErpDoFornecedor;
}

type ResponseFormat = {
  data: ProductDataToTable;
  message: string;
  success: boolean;
};

export default async function revalidaItem(
  props: RevalidaProps,
): Promise<ResponseFormat> {
  const {
    dadosItemACorrigir,
    listaDeStatus,
    parametrosValidacao,
    dadosDaCapa,
    dadosErpDoFornecedor,
  } = props;
  let achouReferencia = true;
  // let referencia = '';
  let formatedItem = dadosItemACorrigir;

  try {
    // Verifica se possui o status NE - Não encontrado
    if (listaDeStatus.includes(1)) {
      // api para buscar item pela referência do fornecedor
      const {
        data: { data },
      } = await api.post('/buscaPorReferencia', {
        des_referencia: dadosItemACorrigir.cProd,
        cod_fornecedor: dadosItemACorrigir.cod_fornecedor,
        cod_loja: dadosItemACorrigir.cod_loja,
      });
      if (data.length <= 0) {
        achouReferencia = false;
      }
      if (
        data.length > 0 &&
        data[0].des_referencia !== dadosItemACorrigir.cProd
      ) {
        const response = await api.post('/buscaPorReferencia', {
          des_referencia: dadosItemACorrigir.cProd,
          cod_fornecedor: dadosItemACorrigir.cod_fornecedor,
          cod_loja: dadosItemACorrigir.cod_loja,
          removeZeros: true,
        });
        if (
          response.data.data.length > 0 &&
          response.data.data[0].des_referencia !== dadosItemACorrigir.cProd
        ) {
          achouReferencia = false;
          // referencia = '';
        } else {
          achouReferencia = true;
          // referencia = response.data.data[0].des_referencia;
        }
      }
      // Caso tenha encontrado a referência
      if (achouReferencia) {
        const dadosParaFormatacao = {
          item: {
            index: dadosItemACorrigir.index,
            edicao: false,
            erpCodProduto: data[0].cod_produto,
            erpCodGtin: data[0].cod_gtin_principal,
            xmlCProd: dadosItemACorrigir.cProd,
            xmlCean: dadosItemACorrigir.cEAN,
            xmlCeanTrib: dadosItemACorrigir.cEANTrib,
            xProd: dadosItemACorrigir.xProd,
            xmlCfop: dadosItemACorrigir.xmlCFOP,
            xmlUCom: dadosItemACorrigir.uCom,
            xmlUTrib: dadosItemACorrigir.uTrib,
            xmlpICMS: dadosItemACorrigir.pICMS,
            xmlpRedBCST: dadosItemACorrigir.pRedBCST,
            xmlpMVAST: dadosItemACorrigir.pMVAST,
            xmlpST: dadosItemACorrigir.pST,
            xmlCEST: dadosItemACorrigir.CEST,
            xmlNCM: dadosItemACorrigir.NCM,
            xmlIcmsCST: dadosItemACorrigir.xmlCST,
            xmlpRedBC: dadosItemACorrigir.pRedBC,
            xmlpICMSST: dadosItemACorrigir.pICMSST,
            xmlvProd: dadosItemACorrigir.vProd,
            xmlvBC: dadosItemACorrigir.vBC,
            xmlCSOSN: dadosItemACorrigir.CSOSN,
            xmlvICMSST: dadosItemACorrigir.vICMSST,
            des_unidade_compra: 'UN',
            perfil: String(parametrosValidacao.cod_perfil),
          },
          fornecedor: {
            uf: dadosErpDoFornecedor.des_uf.value,
            cod_fornecedor: Number(dadosErpDoFornecedor.cod_fornecedor.value),
          },
          loja: {
            uf: dadosDaCapa.des_uf,
            cod_loja: Number(dadosDaCapa.cod_loja),
          },
          parametrosValidacao,
          dadosDaCapa,
          dadosErpDoFornecedor,
        };
        formatedItem = await formataItensParaTabela(dadosParaFormatacao);
        // Caso não tenha encontrado a referência e só deseje buscar pela referência
      } else if (parametrosValidacao.validar_somente_pela_ref) {
        const dadosParaFormatacao = {
          item: {
            index: dadosItemACorrigir.index,
            edicao: false,
            erpCodProduto: '',
            erpCodGtin: '',
            xmlCProd: dadosItemACorrigir.cProd,
            xmlCean: dadosItemACorrigir.cEAN,
            xmlCeanTrib: dadosItemACorrigir.cEANTrib,
            xProd: dadosItemACorrigir.xProd,
            xmlCfop: dadosItemACorrigir.xmlCFOP,
            xmlUCom: dadosItemACorrigir.uCom,
            xmlUTrib: dadosItemACorrigir.uTrib,
            xmlpICMS: dadosItemACorrigir.pICMS,
            xmlpRedBCST: dadosItemACorrigir.pRedBCST,
            xmlpMVAST: dadosItemACorrigir.pMVAST,
            xmlpST: dadosItemACorrigir.pST,
            xmlCEST: dadosItemACorrigir.CEST,
            xmlNCM: dadosItemACorrigir.NCM,
            xmlIcmsCST: dadosItemACorrigir.xmlCST,
            xmlpRedBC: dadosItemACorrigir.pRedBC,
            xmlpICMSST: dadosItemACorrigir.pICMSST,
            xmlvProd: dadosItemACorrigir.vProd,
            xmlvBC: dadosItemACorrigir.vBC,
            xmlCSOSN: dadosItemACorrigir.CSOSN,
            xmlvICMSST: dadosItemACorrigir.vICMSST,
            des_unidade_compra: 'UN',
            perfil: String(parametrosValidacao.cod_perfil),
          },
          fornecedor: {
            uf: dadosErpDoFornecedor.des_uf.value,
            cod_fornecedor: Number(dadosErpDoFornecedor.cod_fornecedor.value),
          },
          loja: {
            uf: dadosDaCapa.des_uf,
            cod_loja: Number(dadosDaCapa.cod_loja),
          },
          parametrosValidacao,
          dadosDaCapa,
          dadosErpDoFornecedor,
        };
        formatedItem = await formataItensParaTabela(dadosParaFormatacao);
        // Caso não tenha encontrado a referência e deseje buscar pelo código de barras
      } else {
        const response = await api.post('/buscaPorCodigoEan', {
          cod_ean: dadosItemACorrigir.cEAN,
          cod_loja: dadosItemACorrigir.cod_loja,
          cod_fornecedor: dadosItemACorrigir.cod_fornecedor,
        });
        // caso não encontre a referência
        if (response.data.data.length <= 0) {
          const dadosParaFormatacao = {
            item: {
              index: dadosItemACorrigir.index,
              edicao: false,
              erpCodProduto: '',
              erpCodGtin: '',
              xmlCProd: dadosItemACorrigir.cProd,
              xmlCean: dadosItemACorrigir.cEAN,
              xmlCeanTrib: dadosItemACorrigir.cEANTrib,
              xProd: dadosItemACorrigir.xProd,
              xmlCfop: dadosItemACorrigir.xmlCFOP,
              xmlUCom: dadosItemACorrigir.uCom,
              xmlUTrib: dadosItemACorrigir.uTrib,
              xmlpICMS: dadosItemACorrigir.pICMS,
              xmlpRedBCST: dadosItemACorrigir.pRedBCST,
              xmlpMVAST: dadosItemACorrigir.pMVAST,
              xmlpST: dadosItemACorrigir.pST,
              xmlCEST: dadosItemACorrigir.CEST,
              xmlNCM: dadosItemACorrigir.NCM,
              xmlIcmsCST: dadosItemACorrigir.xmlCST,
              xmlpRedBC: dadosItemACorrigir.pRedBC,
              xmlpICMSST: dadosItemACorrigir.pICMSST,
              xmlvProd: dadosItemACorrigir.vProd,
              xmlvBC: dadosItemACorrigir.vBC,
              xmlCSOSN: dadosItemACorrigir.CSOSN,
              xmlvICMSST: dadosItemACorrigir.vICMSST,
              des_unidade_compra: 'UN',
              perfil: String(parametrosValidacao.cod_perfil),
            },
            fornecedor: {
              uf: dadosErpDoFornecedor.des_uf.value,
              cod_fornecedor: Number(dadosErpDoFornecedor.cod_fornecedor.value),
            },
            loja: {
              uf: dadosDaCapa.des_uf,
              cod_loja: Number(dadosDaCapa.cod_loja),
            },
            parametrosValidacao,
            dadosDaCapa,
            dadosErpDoFornecedor,
          };
          formatedItem = await formataItensParaTabela(dadosParaFormatacao);
          // caso encontre a referência
        } else {
          const dadosParaFormatacao = {
            item: {
              index: dadosItemACorrigir.index,
              edicao: false,
              erpCodProduto: response.data.data[0].cod_produto,
              erpCodGtin: response.data.data[0].cod_gtin_principal,
              xmlCProd: dadosItemACorrigir.cProd,
              xmlCean: dadosItemACorrigir.cEAN,
              xmlCeanTrib: dadosItemACorrigir.cEANTrib,
              xProd: dadosItemACorrigir.xProd,
              xmlCfop: dadosItemACorrigir.xmlCFOP,
              xmlUCom: dadosItemACorrigir.uCom,
              xmlUTrib: dadosItemACorrigir.uTrib,
              xmlpICMS: dadosItemACorrigir.pICMS,
              xmlpRedBCST: dadosItemACorrigir.pRedBCST,
              xmlpMVAST: dadosItemACorrigir.pMVAST,
              xmlpST: dadosItemACorrigir.pST,
              xmlCEST: dadosItemACorrigir.CEST,
              xmlNCM: dadosItemACorrigir.NCM,
              xmlIcmsCST: dadosItemACorrigir.xmlCST,
              xmlpRedBC: dadosItemACorrigir.pRedBC,
              xmlpICMSST: dadosItemACorrigir.pICMSST,
              xmlvProd: dadosItemACorrigir.vProd,
              xmlvBC: dadosItemACorrigir.vBC,
              xmlCSOSN: dadosItemACorrigir.CSOSN,
              xmlvICMSST: dadosItemACorrigir.vICMSST,
              des_unidade_compra: 'UN',
              perfil: String(parametrosValidacao.cod_perfil),
            },
            fornecedor: {
              uf: dadosErpDoFornecedor.des_uf.value,
              cod_fornecedor: Number(dadosErpDoFornecedor.cod_fornecedor.value),
            },
            loja: {
              uf: dadosDaCapa.des_uf,
              cod_loja: Number(dadosDaCapa.cod_loja),
            },
            parametrosValidacao,
            dadosDaCapa,
            dadosErpDoFornecedor,
          };
          formatedItem = await formataItensParaTabela(dadosParaFormatacao);
        }
      }
      // validar
      const produtoNaoEncontrado = formatedItem.statusDaValidacao.find(
        (status) => status.codStatus === 1,
      );

      let itensValidados = formatedItem;
      if (!produtoNaoEncontrado) {
        itensValidados = await validaItensDoXML(
          formatedItem,
          dadosDaCapa,
          parametrosValidacao,
          dadosErpDoFornecedor,
        );
      }
      const qtdStatusBloq = itensValidados?.statusDaValidacao.filter(
        (status) => status.restricao === 'BLOQ',
      );
      const qtdStatusNe = itensValidados?.statusDaValidacao.filter(
        (status) => status.restricao === 'NE',
      );
      const qtdStatusMix = itensValidados?.statusDaValidacao.filter(
        (status) => status.restricao === 'MIX ',
      );
      const qtdStatusInf = itensValidados?.statusDaValidacao.filter(
        (status) => status.restricao === 'INF',
      );
      itensValidados.qtdStatusBloq = Number(qtdStatusBloq?.length);
      itensValidados.qtdStatusNe = Number(qtdStatusNe?.length);
      itensValidados.qtdStatusMix = Number(qtdStatusMix?.length);
      itensValidados.qtdStatusInf = Number(qtdStatusInf?.length);

      return {
        data: itensValidados,
        message: 'Item revalidado com sucesso.',
        success: true,
      };
    }
    // validarproduto
    const dadosParaFormatacao = {
      item: {
        index: dadosItemACorrigir.index,
        edicao: false,
        erpCodProduto: '',
        erpCodGtin: dadosItemACorrigir.cod_gtin_principal,
        xmlCProd: dadosItemACorrigir.cProd,
        xmlCean: dadosItemACorrigir.cEAN,
        xmlCeanTrib: dadosItemACorrigir.cEANTrib,
        xProd: dadosItemACorrigir.xProd,
        xmlCfop: dadosItemACorrigir.xmlCFOP,
        xmlUCom: dadosItemACorrigir.uCom,
        xmlUTrib: dadosItemACorrigir.uTrib,
        xmlpICMS: dadosItemACorrigir.pICMS,
        xmlpRedBCST: dadosItemACorrigir.pRedBCST,
        xmlpMVAST: dadosItemACorrigir.pMVAST,
        xmlpST: dadosItemACorrigir.pST,
        xmlCEST: dadosItemACorrigir.CEST,
        xmlNCM: dadosItemACorrigir.NCM,
        xmlIcmsCST: dadosItemACorrigir.xmlCST,
        xmlpRedBC: dadosItemACorrigir.pRedBC,
        xmlpICMSST: dadosItemACorrigir.pICMSST,
        xmlvProd: dadosItemACorrigir.vProd,
        xmlvBC: dadosItemACorrigir.vBC,
        xmlCSOSN: dadosItemACorrigir.CSOSN,
        xmlvICMSST: dadosItemACorrigir.vICMSST,
        des_unidade_compra: 'UN',
        perfil: String(parametrosValidacao.cod_perfil),
      },
      fornecedor: {
        uf: dadosErpDoFornecedor.des_uf.value,
        cod_fornecedor: Number(dadosErpDoFornecedor.cod_fornecedor.value),
      },
      loja: {
        uf: dadosDaCapa.des_uf,
        cod_loja: Number(dadosDaCapa.cod_loja),
      },
      parametrosValidacao,
      dadosDaCapa,
      dadosErpDoFornecedor,
    };
    formatedItem = await formataItensParaTabela(dadosParaFormatacao);
    const produtoNaoEncontrado = formatedItem.statusDaValidacao.find(
      (status) => status.codStatus === 1,
    );

    let itensValidados = formatedItem;
    if (!produtoNaoEncontrado) {
      itensValidados = await validaItensDoXML(
        formatedItem,
        dadosDaCapa,
        parametrosValidacao,
        dadosErpDoFornecedor,
      );
    }
    const qtdStatusBloq = itensValidados?.statusDaValidacao.filter(
      (status) => status.restricao === 'BLOQ',
    );
    const qtdStatusNe = itensValidados?.statusDaValidacao.filter(
      (status) => status.restricao === 'NE',
    );
    const qtdStatusMix = itensValidados?.statusDaValidacao.filter(
      (status) => status.restricao === 'MIX ',
    );
    const qtdStatusInf = itensValidados?.statusDaValidacao.filter(
      (status) => status.restricao === 'INF',
    );
    itensValidados.qtdStatusBloq = Number(qtdStatusBloq?.length);
    itensValidados.qtdStatusNe = Number(qtdStatusNe?.length);
    itensValidados.qtdStatusMix = Number(qtdStatusMix?.length);
    itensValidados.qtdStatusInf = Number(qtdStatusInf?.length);
    return {
      data: itensValidados,
      message: 'Item revalidado com sucesso.',
      success: true,
    };
  } catch (error) {
    return {
      data: formatedItem,
      message: JSON.stringify(error),
      success: false,
    };
  }

  // insereDadosItem com referencia que existe
  // if CliMItensSTATUS.AsString <> 'N' then
  // validarproduto
}
