import api from '~/services/api';
import { validaGtin } from '~/utils/functions';
import {
  CapaData,
  DadosErpDoFornecedor,
  ParamentrosValidacao,
  ProductDataToTable,
} from '../../protocols';
import { validaItensDoXML } from '../Validacao';

interface RevalidaProps {
  dadosItemACorrigir: ProductDataToTable;
  parametrosValidacao: ParamentrosValidacao;
  dadosDaCapa: CapaData;
  dadosErpDoFornecedor: DadosErpDoFornecedor;
  codigoDeBarra: string;
}
type ResponseFormat = {
  data: ProductDataToTable;
  message: string;
  success: boolean;
};

/**
 * @param atualizaProdutoFornecedor Valida se o GTIN já existe
 */
async function verificaGtinExistente(
  item: ProductDataToTable,
  codigoDeBarra: string,
): Promise<any | undefined> {
  const { data } = await api.post('/verifica-gtin-existente', {
    cod_gtin: codigoDeBarra,
    cod_produto: item.cod_produto,
  });
  const { success } = data;
  if (success && data.data) {
    return data;
  }
}

/**
 * @param vinculaGtinAoProduto Vincula o GTIN ao item sendo editado
 */
async function vinculaGtinAoProduto(
  item: ProductDataToTable,
  codigoDeBarra: string,
): Promise<any | undefined> {
  const { data } = await api.post('/vincula-gtin-ao-produto', {
    cod_gtin: codigoDeBarra,
    cod_produto: item.cod_produto,
  });
  const { success } = data;
  if (success && data.data) {
    return data;
  }
}

/**
 * @param atualizaCodigoGtin Método responsável por vincular um novo GTIN ao item
 */
export default async function atualizaCodigoGtin(
  props: RevalidaProps,
): Promise<ResponseFormat> {
  const {
    dadosItemACorrigir,
    parametrosValidacao,
    dadosDaCapa,
    dadosErpDoFornecedor,
    codigoDeBarra,
  } = props;
  const formatedItem = dadosItemACorrigir;
  try {
    /**
     * Valida o GTIN
     */

    const hasValidGtin = validaGtin(codigoDeBarra, codigoDeBarra.length);
    if (!hasValidGtin.valid) {
      return {
        data: formatedItem,
        message: `Código Gtin ${codigoDeBarra} é inválido.`,
        success: false,
      };
    }

    /**
     * Verifica se o GTIN já existe no item ou em qualquer outro
     */
    const gtinExist = await verificaGtinExistente(
      dadosItemACorrigir,
      codigoDeBarra,
    );

    if (gtinExist.message === 'Success' && gtinExist.data.length > 0) {
      return {
        data: formatedItem,
        message: `Foi encontrado um item com gtin ${codigoDeBarra} já cadastrado.`,
        success: false,
      };
    }

    /**
     * Vincula o GTIN ao item sendo editado
     */
    await vinculaGtinAoProduto(dadosItemACorrigir, codigoDeBarra);

    /**
     * Revalida o item sendo editado
     */
    const cleanStatus = dadosItemACorrigir.statusDaValidacao.filter(
      (status) => {
        return status.codStatus !== 200 && status.codStatus !== 201;
      },
    );

    dadosItemACorrigir.statusDaValidacao = cleanStatus;
    dadosItemACorrigir.flg_gtin_vinculado = true;
    formatedItem.flg_gtin_vinculado = true;

    const itensValidados = await validaItensDoXML(
      formatedItem,
      dadosDaCapa,
      parametrosValidacao,
      dadosErpDoFornecedor,
    );

    const qtdStatusBloq = itensValidados?.statusDaValidacao.filter(
      (status) => status.restricao === 'BLOQ',
    );
    const qtdStatusNe = itensValidados?.statusDaValidacao.filter(
      (status) => status.restricao === 'NE',
    );
    const qtdStatusMix = itensValidados?.statusDaValidacao.filter(
      (status) => status.restricao === 'MIX ',
    );
    const qtdStatusInf = itensValidados?.statusDaValidacao.filter(
      (status) => status.restricao === 'INF',
    );
    itensValidados.qtdStatusBloq = Number(qtdStatusBloq?.length);
    itensValidados.qtdStatusNe = Number(qtdStatusNe?.length);
    itensValidados.qtdStatusMix = Number(qtdStatusMix?.length);
    itensValidados.qtdStatusInf = Number(qtdStatusInf?.length);

    return {
      data: itensValidados,
      message: 'Código GTIN Vinculado com Sucesso.',
      success: true,
    };
  } catch (error: any) {
    return {
      data: formatedItem,
      message: error.data.message,
      success: false,
    };
  }
}
