import { NovoProduto } from '../../Components/ModalDeCorrecoes/protocols';
import {
  CapaData,
  DadosErpDoFornecedor,
  ParamentrosValidacao,
  ProductDataToTable,
} from '../../protocols';
import { formataItensParaTabela, validaItensDoXML } from '../Validacao';

export default async function substituirProduto(
  novoProduto: NovoProduto,
  itemASubstituir: ProductDataToTable,
  parametrosValidacao: ParamentrosValidacao,
  dadosErpDoFornecedor: DadosErpDoFornecedor,
  dadosDaCapa: CapaData,
): Promise<ProductDataToTable> {
  const dadosParaFormatacao = {
    item: {
      index: itemASubstituir.index,
      edicao: false,
      erpCodProduto: novoProduto.cod_produto,
      erpCodGtin: novoProduto.cod_gtin_principal,
      xmlCProd: itemASubstituir.cProd,
      xmlCean: itemASubstituir.cEAN,
      xmlCeanTrib: itemASubstituir.cEANTrib,
      xProd: itemASubstituir.xProd,
      xmlCfop: itemASubstituir.xmlCFOP,
      xmlUCom: itemASubstituir.uCom,
      xmlUTrib: itemASubstituir.uTrib,
      xmlpICMS: itemASubstituir.pICMS,
      xmlpRedBCST: itemASubstituir.pRedBCST,
      xmlpMVAST: itemASubstituir.pMVAST,
      xmlpST: itemASubstituir.pST,
      xmlCEST: itemASubstituir.CEST,
      xmlNCM: itemASubstituir.NCM,
      xmlIcmsCST: itemASubstituir.icsmCST,
      xmlpRedBC: itemASubstituir.pRedBC,
      xmlpICMSST: itemASubstituir.pICMSST,
      xmlvProd: itemASubstituir.vProd,
      xmlvBC: itemASubstituir.vBC,
      xmlCSOSN: itemASubstituir.CSOSN,
      xmlvICMSST: itemASubstituir.vICMSST,
      des_unidade_compra: itemASubstituir.uCom,
      perfil: String(parametrosValidacao.cod_perfil),
    },
    fornecedor: {
      uf: dadosErpDoFornecedor.des_uf.value,
      cod_fornecedor: Number(dadosErpDoFornecedor.cod_fornecedor.value),
    },
    loja: {
      uf: dadosDaCapa.des_uf,
      cod_loja: Number(dadosDaCapa.cod_loja),
    },
    parametrosValidacao,
    dadosDaCapa,
    dadosErpDoFornecedor,
  };
  const itensFormatados = await formataItensParaTabela(dadosParaFormatacao);
  const produtoNaoEncontrado = itensFormatados.statusDaValidacao.find(
    (status) => status.codStatus === 1,
  );

  let itensValidados = itensFormatados;
  if (!produtoNaoEncontrado) {
    itensValidados = await validaItensDoXML(
      itensFormatados,
      dadosDaCapa,
      parametrosValidacao,
      dadosErpDoFornecedor,
    );
  }
  const qtdStatusBloq = itensValidados.statusDaValidacao.filter(
    (status) => status.restricao === 'BLOQ',
  );
  const qtdStatusNe = itensValidados.statusDaValidacao.filter(
    (status) => status.restricao === 'NE',
  );
  const qtdStatusMix = itensValidados.statusDaValidacao.filter(
    (status) => status.restricao === 'MIX ',
  );
  const qtdStatusInf = itensValidados.statusDaValidacao.filter(
    (status) => status.restricao === 'INF',
  );
  itensValidados.qtdStatusBloq = Number(qtdStatusBloq?.length);
  itensValidados.qtdStatusNe = Number(qtdStatusNe?.length);
  itensValidados.qtdStatusMix = Number(qtdStatusMix?.length);
  itensValidados.qtdStatusInf = Number(qtdStatusInf?.length);

  return itensValidados;
}
