import {
  CapaData,
  DadosErpDoFornecedor,
  ParamentrosValidacao,
  ProductDataToTable,
} from '../../protocols';
import { validaItensDoXML } from '../Validacao';

export default async function revalidaItemUnico(
  itemACorrigir: ProductDataToTable,
  parametrosValidacao: ParamentrosValidacao,
  dadosErpDoFornecedor: DadosErpDoFornecedor,
  dadosDaCapa: CapaData,
): Promise<ProductDataToTable> {
  const itensValidados = await validaItensDoXML(
    itemACorrigir,
    dadosDaCapa,
    parametrosValidacao,
    dadosErpDoFornecedor,
    true,
  );
  const qtdStatusBloq = itensValidados.statusDaValidacao.filter(
    (status) => status.restricao === 'BLOQ',
  );
  const qtdStatusNe = itensValidados.statusDaValidacao.filter(
    (status) => status.restricao === 'NE',
  );
  const qtdStatusMix = itensValidados.statusDaValidacao.filter(
    (status) => status.restricao === 'MIX ',
  );
  const qtdStatusInf = itensValidados.statusDaValidacao.filter(
    (status) => status.restricao === 'INF',
  );
  itensValidados.qtdStatusBloq = Number(qtdStatusBloq?.length);
  itensValidados.qtdStatusNe = Number(qtdStatusNe?.length);
  itensValidados.qtdStatusMix = Number(qtdStatusMix?.length);
  itensValidados.qtdStatusInf = Number(qtdStatusInf?.length);

  return itensValidados;
}
