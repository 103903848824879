import api from '~/services/api';
import {
  CapaData,
  DadosErpDoFornecedor,
  ParamentrosValidacao,
  ProductDataToTable,
} from '../../protocols';
import { validaItensDoXML } from '../Validacao';
import { toast } from 'react-toastify';

interface RevalidaProps {
  dadosItemACorrigir: ProductDataToTable;
  parametrosValidacao: ParamentrosValidacao;
  dadosDaCapa: CapaData;
  dadosErpDoFornecedor: DadosErpDoFornecedor;
}

export default async function atualizarUnidadeCompraEReferencia(
  props: RevalidaProps,
): Promise<ProductDataToTable> {
  const {
    dadosItemACorrigir,
    parametrosValidacao,
    dadosDaCapa,
    dadosErpDoFornecedor,
  } = props;
  const formatedItem = dadosItemACorrigir;
  try {
    const { data } = await api.put(
      '/atualizar-unidade-de-compra-e-referencia',
      {
        cod_fornecedor: dadosItemACorrigir.cod_fornecedor,
        cod_loja: dadosItemACorrigir.cod_loja,
        cod_produto: dadosItemACorrigir.cod_produto,
        des_referencia: dadosItemACorrigir.cProd,
        qtd_embalagem_compra: dadosItemACorrigir.qtd_embalagem_compra,
        des_unidade_compra: dadosItemACorrigir.uCom,
        atualiza_forn_preferencial:
          parametrosValidacao.atualiza_forn_preferencial,
      },
    );
    if (!data.success && data.data.length <= 0) {
      toast.error(
        `Não foi possível atualizar a Unidade de compra e Referência: ${data.message}`,
      );
      return formatedItem;
    }
    let cod_seq_produto_fornecedor = null;
    if (data.data.cod_seq_produto_fornecedor) {
      cod_seq_produto_fornecedor = data.data.cod_seq_produto_fornecedor;
    }
    if (data.data.length > 0) {
      cod_seq_produto_fornecedor = data.data[0].cod_seq_produto_fornecedor;
    }

    if (cod_seq_produto_fornecedor) {
      formatedItem.des_unidade_compra = dadosItemACorrigir.uCom;

      if (typeof formatedItem.statusDaValidacao !== 'string') {
        // remove status atualizar cpra e ref
        const cleanStatus = formatedItem.statusDaValidacao.filter(
          (status) => status.codStatus !== 100,
        );
        formatedItem.statusDaValidacao = cleanStatus;

        const itensValidados = await validaItensDoXML(
          formatedItem,
          dadosDaCapa,
          parametrosValidacao,
          dadosErpDoFornecedor,
        );
        const qtdStatusBloq = itensValidados?.statusDaValidacao.filter(
          (status) => status.restricao === 'BLOQ',
        );
        const qtdStatusNe = itensValidados?.statusDaValidacao.filter(
          (status) => status.restricao === 'NE',
        );
        const qtdStatusMix = itensValidados?.statusDaValidacao.filter(
          (status) => status.restricao === 'MIX ',
        );
        const qtdStatusInf = itensValidados?.statusDaValidacao.filter(
          (status) => status.restricao === 'INF',
        );
        itensValidados.qtdStatusBloq = Number(qtdStatusBloq?.length);
        itensValidados.qtdStatusNe = Number(qtdStatusNe?.length);
        itensValidados.qtdStatusMix = Number(qtdStatusMix?.length);
        itensValidados.qtdStatusInf = Number(qtdStatusInf?.length);

        toast.success('Unidade Cpra. e Ref. Atualizada com sucesso.');
        return itensValidados;
      }
    }
    toast.warning(
      'Não foi possível atualizar a Unidade de compra e Referência.',
    );
    return formatedItem;
  } catch (error: any) {
    if (error.data && error.data.message) {
      toast.error(error.data.message);
      return formatedItem;
    }
    toast.error(String(error));
    return formatedItem;
  }
}
